import React from 'react'
import './DescriptionBox.css'

const DescriptionBox = () => {
  return (
    <div className='descriptionbox'>
        <div className="descriptionbox-navigator">
            <div className="descriptionbox-nav-box">Description</div>
            <div className="descriptionbox-nav-box fade">Reviews (122)</div>
        </div>
        <div className="descriptionbox-description">
            <p>
            An e-commerce website is a digital platform where individuals and businesses can easily buy and sell products or services online. It functions as a virtual marketplace, allowing businesses and individuals to display their products, engage with customers, and carry out transactions without the necessity of a physical storefront. The popularity of e-commerce websites has soared, driven by their convenience, accessibility, and the global market reach they provide.
            </p>
            <p>

            </p>
        </div>
    </div>
  )
}

export default DescriptionBox
